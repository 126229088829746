import { ConsentRegistry } from '@schibsted/consent-registry';

import type { SessionData } from './schibsted-account/types.js';

import { isLocalhost } from './utils/is-localhost.js';

export async function initMblTracker(sessionData: SessionData): Promise<void> {
  const [consentGranted] = await ConsentRegistry.useIntegration('MBL', () => {
    // TODO: disable MBL when consent is revoked
  });

  if (!consentGranted) return;

  const isLoggedIn = sessionData && !!sessionData.user.userId;
  const isSubscriber = sessionData && sessionData.access.entitled;
  const userUuid = sessionData && sessionData.user.uuid;

  const subscriberId = userUuid && (await getHashedUserUuidWithDate(userUuid));

  addMetaElement('mbl:login', isLoggedIn);
  addMetaElement('k5a:login', isLoggedIn);
  addMetaElement('mbl:subscriber', isSubscriber);
  addMetaElement('k5a:subscriber', isSubscriber);

  if (subscriberId) {
    addMetaElement('mbl:subscriberid', subscriberId);
    addMetaElement('k5a:subscriberid', subscriberId);
  }

  // but do not load the tracking script for the localhost
  if (!isLocalhost()) {
    const mblScript = document.createElement('script');
    mblScript.defer = true;
    mblScript.src = 'https://log.medietall.no/analytics.js';
    document.head.appendChild(mblScript);
  }
}

const addMetaElement = (property: string, value: boolean | string) => {
  const mblSubscriberMeta = document.createElement('meta');
  mblSubscriberMeta.setAttribute('property', property);
  mblSubscriberMeta.setAttribute('content', `${value}`);
  document.head.appendChild(mblSubscriberMeta);
};

const sha256 = async (input: string): Promise<string> => {
  try {
    //https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
    const encodedData = new TextEncoder().encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  } catch (e) {
    console.error(`Could not hash input. Error: ${e}`);

    return '';
  }
};

const getHashedUserUuidWithDate = async (userUuid: string): Promise<string> => {
  const today = new Date()
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');

  return await sha256(`${userUuid}${today}`);
};
