import { useState, useEffect } from 'react';

import type { SessionData } from './types.js';

import { console } from '../../../../src/core/system/console/index.js';

export const useSessionData = (): SessionData | null => {
  const [session, setSession] = useState<SessionData | null>(null);

  useEffect(() => {
    const checkSession = async () => {
      const { getSessionData } = await import('./index.client.js');
      const maybeSession = await getSessionData();

      if (maybeSession) {
        setSession(maybeSession);
      } else {
        setSession(false);
      }
    };

    checkSession().catch((error) => {
      console.error(error);
    });
  }, []);

  return session;
};
